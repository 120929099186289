import * as React from "react";

import { Layout } from "../components/Layout/Layout";
import Seo from "../components/seo";
import { SupportPageComponent } from "../components/SupportPageComponent/SupportPageComponent";

const Contacts = () => (
    <Layout>
        <Seo title="Contacts" />
        <SupportPageComponent />
    </Layout>
);

export default Contacts;
