import styled from "styled-components";
import { StyledText, StyledTitle } from "../IndexPageComponent/style";
import { titleColor, bgSecond } from "../../styles/variables";

export const StyledCustomerSupport = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 100px 0px;
`;

export const StyledInfoWrapper = styled.div`
    flex: 0 0 460px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    ${StyledText} {
        padding: 0 50px;
        color: ${titleColor};
    }
`;

export const StyledButtonWrapper = styled.div`
    margin-top: 35px;
`;

export const StyledEmailsWrapper = styled.div`
    background-color: ${bgSecond};
    padding: 80px 130px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-around;
`;

export const StyledEmail = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 40%;
    align-items: center;

    ${StyledTitle} {
        padding-top: 30px;
        display: flex;
        justify-content: flex-start;
        flex: 1 1 100%;

        & > h2 {
            margin: 0;
            text-align: left;
        }
    }

    & > a {
        text-decoration: none;
    }

    ${StyledText} {
        color: ${titleColor};
        font-weight: 400;
        margin-left: 10px;
    }
`;
