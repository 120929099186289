import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Footer } from "../Footer/Footer";
import { Star } from "../Star/Star";
import { Decorates } from "../Decorates/Decorates";

import { StyledCustomerSupport, StyledInfoWrapper, StyledButtonWrapper, StyledEmailsWrapper, StyledEmail } from "./style";

import { StyledTitle, StyledText } from "../IndexPageComponent/style";

import { StyledBorder, StyledPageWrapper, StyledDecoratesWrapper, StyledHeaderWrapper } from "../IndexPageComponent/style";

const firstGameStyle = {
    position: "absolute",
    top: "-416px",
    left: "-511px",
    opacity: "0.4",
    transform: "rotate(-62deg) rotateY(180deg)"
};

const secondGameStyle = {
    width: "850px",
    position: "absolute",
    top: "-388px",
    left: "923px",
    opacity: ".4",
    transform: "rotateY(180deg) rotate(-300deg) scale(0.8)"
};

const owlStyle = {
    position: "absolute",
    bottom: "110px",
    left: "-70px",
    opacity: ".4",
    transform: "scale(0.8)"
};

export const SupportPageComponent = () => (
    <StyledPageWrapper>
        <StyledHeaderWrapper>
            <StaticImage src="../../images/contacts_header.png" width={300} placeholder="blurred" alt="contacts header" />
        </StyledHeaderWrapper>
        <StyledBorder>
            <StyledDecoratesWrapper>
                <Decorates firstGameStyle={firstGameStyle} secondGameStyle={secondGameStyle} owlStyle={owlStyle} />
                <StyledCustomerSupport>
                    <StyledInfoWrapper>
                        <StyledTitle>
                            <Star />
                            <h2>customer support</h2>
                            <Star />
                        </StyledTitle>
                        <StyledText>Contact us if you have any questions and will be happy to help</StyledText>
                        <a href="mailto:support@may-play.com">
                            <StyledButtonWrapper>
                                <StaticImage src="../../images/contact_btn.png" alt="contact btn" placeholder="blurred" />
                            </StyledButtonWrapper>
                        </a>
                    </StyledInfoWrapper>
                    <StaticImage src="../../images/man.png" alt="man" placeholder="blurred" />
                </StyledCustomerSupport>
                <StyledEmailsWrapper>
                    <StyledEmail>
                        <StyledTitle>
                            <h2>general questions</h2>
                        </StyledTitle>
                        <Star />
                        <a className="text" href="mailto:general@may-play.com">
                            <StyledText>general@may-play.com</StyledText>
                        </a>
                    </StyledEmail>
                    <StyledEmail>
                        <StyledTitle>
                            <h2>customer support</h2>
                        </StyledTitle>
                        <Star />
                        <a className="text" href="mailto:support@may-play.com">
                            <StyledText>support@may-play.com</StyledText>
                        </a>
                    </StyledEmail>
                    <StyledEmail>
                        <StyledTitle>
                            <h2>vacancy</h2>
                        </StyledTitle>
                        <Star />
                        <a className="text" href="mailto:vacancy@may-play.com">
                            <StyledText>vacancy@may-play.com</StyledText>
                        </a>
                    </StyledEmail>
                    <StyledEmail>
                        <StyledTitle>
                            <h2>business</h2>
                        </StyledTitle>
                        <Star />
                        <a href="mailto:business@may-play.com">
                            <StyledText>business@may-play.com</StyledText>
                        </a>
                    </StyledEmail>
                </StyledEmailsWrapper>
                <Footer />
            </StyledDecoratesWrapper>
        </StyledBorder>
    </StyledPageWrapper>
);
